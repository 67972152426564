/**
 * Licensed to the Apache Software Foundation (ASF) under one
 * or more contributor license agreements.  See the NOTICE file
 * distributed with this work for additional information
 * regarding copyright ownership.  The ASF licenses this file
 * to you under the Apache License, Version 2.0 (the
 * "License"); you may not use this file except in compliance
 * with the License.  You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */
import emotionStyled from '@emotion/styled';
import { useTheme as useThemeBasic } from '@emotion/react';
import createCache from '@emotion/cache';

export {
  css,
  keyframes,
  jsx,
  ThemeProvider,
  CacheProvider as EmotionCacheProvider,
  withTheme,
} from '@emotion/react';
export { default as createEmotionCache } from '@emotion/cache';

declare module '@emotion/react' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  export interface Theme extends SupersetTheme {}
}

export function useTheme() {
  const theme = useThemeBasic();
  // in the case there is no theme, useTheme returns an empty object
  if (Object.keys(theme).length === 0 && theme.constructor === Object) {
    throw new Error(
      'useTheme() could not find a ThemeContext. The <ThemeProvider/> component is likely missing from the app.',
    );
  }
  return theme;
}

export const emotionCache = createCache({
  key: 'superset',
});

export const styled = emotionStyled;

const defaultTheme = {
  borderRadius: 4,
  colors: {
    text: {
      label: '#979797',
      help: '#727272',
    },
    primary: {
      base: '#1A76E2',
      dark1: '#075BBE',
      dark2: '#06458D',
      light1: '#7995DE',
      light2: '#A5B8E9',
      light3: '#D2E0F4',
      light4: '#E9EEF9',
      light5: '#F3F5FA',
    },
    secondary: {
      base: '#5D6F7F',
      dark1: '#1F364A',
      dark2: '#202E3B',
      dark3: '#17212A',
      light1: '#ABBAFC',
      light2: '#B7B7B7',
      light3: '#D9D9D9',
      light4: '#EEEEEE',
      light5: '#F4F4F4',
    },
    grayscale: {
      base: '#585858',
      dark1: '#1F364A',
      dark2: '#000000',
      light1: '#979797',
      light2: '#E6E6E6',
      light3: '#EEEEEE',
      light4: '#F4F4F4',
      light5: '#FFFFFF',
    },
    error: {
      base: '#DB0444',
      dark1: '#BA0D41',
      dark2: '#97022F',
      light1: '#FFE9CF',
      light2: '#FDF3F1',
    },
    warning: {
      base: '#F2A504',
      dark1: '#FF7A1A',
      dark2: '#926200',
      light1: '#FFE03A',
      light2: '#FFFABB',
    },
    alert: {
      base: '#F8E71B',
      dark1: '#BCA901',
      dark2: '#7D6900',
      light1: '#FDE980',
      light2: '#FFFCEB',
    },
    success: {
      base: '#65C84D',
      dark1: '#1C9B7D',
      dark2: '#1D9052',
      light1: '#BBE8DE',
      light2: '#E7FBE8',
    },
    info: {
      base: '#58BDF9',
      dark1: '#1B88FE',
      dark2: '#075BBE',
      light1: '#E9EEF9',
      light2: '#DAEBFF',
    },
  },
  opacity: {
    light: '10%',
    mediumLight: '35%',
    mediumHeavy: '60%',
    heavy: '80%',
  },
  typography: {
    families: {
      sansSerif: `'Inter', Helvetica, Arial`,
      serif: `Georgia, 'Times New Roman', Times, serif`,
      monospace: `'Fira Code', 'Courier New', monospace`,
    },
    weights: {
      light: 200,
      normal: 400,
      medium: 500,
      bold: 600,
    },
    sizes: {
      xxs: 9,
      xs: 10,
      s: 12,
      m: 14,
      l: 16,
      xl: 21,
      xxl: 28,
    },
  },
  zIndex: {
    aboveDashboardCharts: 10,
    dropdown: 11,
    max: 3000,
  },
  transitionTiming: 0.3,
  gridUnit: 4,
  brandIconMaxWidth: 37,
};

export type SupersetTheme = typeof defaultTheme;

export interface SupersetThemeProps {
  theme: SupersetTheme;
}

export const supersetTheme = defaultTheme;
